import React, {Component} from "react";
import "./css/Categories.css";

var data = ["Информация о компании",
            "Отчетность",
            "Бухучёт, Зарплата и кадры",
            "Проверка контрагентов",
            "ЭДО",
            "Торговля, онлайн кассы",
            "Торги и закупки",
            "Ведение бизнеса",
            "работа в ГИС",
            "Наши продукты"]
var keys = ["info",
            "Otchetnost",
            "buh",
            "check",
            "doc",
            "torgovla",
            "zakup",
            "bussines",
            "gis",
            "our"]



class Categories extends Component {
    constructor(props) {
        super(props);
    }

    handlerClick(e) {
        var foo = document.querySelector(".active");

        if (foo != null) {
            foo.classList.remove("active");
        }

        e.currentTarget.classList.add("active");

        this.props.handlerReRenderProducts(e.target.getAttribute("data-key"))
    }

    RenderData() {
        var renderData = [];
        for (var i = 0; i < data.length; i++) {
            renderData.push(<a href="#" key={keys[i]} data-key={keys[i]} onClick={(e) => this.handlerClick(e)}>{data[i]}</a>);
        }
        return renderData
    }

    render() {
        return (
            <div className="categories-container">
                {this.RenderData()}
            </div>
        );
    }
};

export default Categories;
