import React, {Component} from "react";
import "./css/App.css";
import Categories from "./Categories";
import ProductList from "./ProductList";
import Logo from "./Logo"
import Info from "./Info.js"


class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            category: "Otchetnost"
        }
    }

    handlerReRenderProducts = (value) => {
        this.setState({ category: value })
    }

    render() {
        return (
            <div className="app-container">
                <div className="app-categories-container">
                    <Logo/>
                    <Categories handlerReRenderProducts={this.handlerReRenderProducts}/>
                </div>
                {this.state.category === "info" ? <Info/> : <ProductList category={this.state.category}/>}
            </div>
        );
    }
};

export default App;