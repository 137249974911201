import React from "react";
import ReactDOM from "react-dom/client";
import "./css/index.css";
import App from "./App";
import Footer from "./Footer";

const root = ReactDOM.createRoot(document.querySelector("#root"));

root.render(
    <><App/><Footer/></>
);