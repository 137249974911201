import React, {Component} from "react";
import "./css/Logo.css";
import img from "./ProductData/logo.svg"

class Logo extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <a href="#" >
                <img className="brand" src= {img}/>
            </a>
        );
    }
};
export default Logo;