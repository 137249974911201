import React, {Component} from "react";
import "./css/Footer.css";

class Footer extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <footer className="container-fluid">
                <div className="container">
                    <div className="txt-container">
                        <div className="our-contacts">
                            <h2>Наши контакты</h2>
                        </div>
                        <div className="social-padding">
                            <div className="footer-row"> 
                                <div className="list-footer">
                                    <div className="col-md-3 col-sm-3">
                                            <span>Наши телефоны:</span> 
                                    </div>
                                    <ul className="tel-list">
                                        <li><span data-tel="8 (35130) 5-30-55" itemProp="telephone" className="tel-num">8 (35130) 5-30-55</span></li>
                                        <li><span data-tel="8 (35130) 5-30-10" itemProp="telephone" className="tel-num">8 (35130) 5-30-10</span></li>
                                        <li><span data-tel="8 (35130) 9-38-22" itemProp="telephone" className="tel-num">8 (35130) 9-38-22</span></li>
                                        <li><span data-tel="8 (35130) 9-46-00" itemProp="telephone" className="tel-num">8 (35130) 9-46-00</span></li>
                                    </ul>
                                </div>
                                <div className="list-footer">
                                    <div className="col-md-3 col-sm-2">
                                            <span>Наша почта:</span>  
                                    </div>
                                    <ul className="mail-list">
                                        <li><span className="email">info@kolbitec.ru</span></li>
                                        <li><span className="email">support@kolbitec.ru</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
};
export default Footer;