import React, {Component} from "react";
import "./css/Info.css";

class Info extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="containerr">
                <h2 className="productTxt-title center">Компания ООО "Колбитек"</h2>
                <p>Являемся официальными партнером Фирмы "СКБ Контур ". Обладаем рядом компетенций, подтвержденных статусами, присвоенными Фирмой "СКБ Контур". Основное направление деятельности нашей компании - проекты полного цикла внедрения и дальнейшего сопровождения на базе решений "СКБ Контур". ООО «Колбитек» непрерывно ведет свою деятельность по распостранению и внедрению программных продуктов семейства "СКБ Контур" успешно реализовали более 100 проектов, различных по масштабу и отраслевой специфике, которые помогли нашим клиентам снизить издержки, выявить неэффективные трудозатраты путем налаживания систем бюджетирования, казначейства и консолидации в решениях "СКБ Контур".</p>
                <br/>
                &nbsp;
                <br/>
                <p>Мы одна из немногих аккредитованных компаний в городе Озёрске, которая имеет статус "Стратегического партнёра СКБ Контур". Опыт и профессионализм нашей команды подтверждается сертификатами и наградами фирмы "СКБ Контур ", среди которых сертификат "Партнёр года", подтверждающий экспертный уровень навыков в нашей команды.</p>
                <br/>
                <p>У нас есть лицензия ФСБ на работу с СКЗИ</p>
                <br/>
                А так же у нас есть продукты занесённые в <a className="ref" href="https://reestr.digital.gov.ru/reestr/1258363/?sphrase_id=2918438">реестр отечественного ПО</a>
                <br/>
                <p>Наш адресс: Челябинская обл, г Озерск, пр-кт Карла Маркса, дом 1 помещение 1</p>
                <br/>
                <p>ИНН-КПП:7422032042-741301001</p>
                <br/>
                <p>ОГРН: 1037401171378</p>
            </div>
        );
    }
};
export default Info;