import React, {Component} from "react";
import "./css/ProductList.css";
import Product from "./Product"

var data = require('./ProductData/Product.json')


class ProductList extends Component {
    constructor(props) {
        super(props);
    }

    RenderData() {
        var renderData = [];
        for (var i = 0; i < data[this.props.category].length; i++) {
            renderData.push(<Product key={data[this.props.category][i].title} href={data[this.props.category][i].link} icon={data[this.props.category][i].img} title={data[this.props.category][i].title} description={data[this.props.category][i].description} /> )
        }
        return renderData;
    }

    render() {
        return (
            <div className="product-list-container">
                {this.RenderData()}
            </div>
        );
    }
};
export default ProductList;