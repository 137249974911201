import React, {Component} from "react";
import "./css/Icon.css";

class Icon extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <span className="icon-container">
                <img className="icon-img" src={this.props.icon}/>
            </span>
        );
    }
};
export default Icon;