import React, {Component} from "react";
import "./css/ProductTxt.css";

class ProductTxt extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <span className="productTxt-container">
                <span className="productTxt-title">{this.props.title}</span>
                {this.props.description}          
            </span>
        );
    }
};
export default ProductTxt;