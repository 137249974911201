import React, {Component} from "react";
import Icon from "./Icon"
import "./css/Product.css";
import ProductTxt from "./ProductTxt"

class Product extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="product-container">
                <a href={this.props.href} target="_blank">
                    <div className="product-icon-txt">
                        <Icon icon={this.props.icon}/>
                        <ProductTxt description={this.props.description} title={this.props.title} />
                    </div>
                </a>
            </div>
        );
    }
};
export default Product;